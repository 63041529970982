body,
html,
#root {
  height: 100%;
}

body {
  background: aliceblue;
}

img {
  filter: opacity(0.5);
  flex: 0 1 100%;
}

.web {
  display: flex;
  height: 75%;
}

.keypad {
  display: grid;
  grid-template-columns: auto auto auto auto;
  flex: 1;
}
